.loaderCont{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    background-color: rgba(#fff,0.9);
    z-index: 9999;
    .loaderWrap{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        .loader{

        }
        .loadingText{
            font-size: 20px;
            font-weight: 600;
        }
    }
}