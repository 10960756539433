@import "../../assets/scss/theme";

.paymentsView{
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    .paymentsMainCont{
        -ms-flex: 0 0 calc(100% - 300px);
        flex: 0 0 calc(100% - 300px);
        max-width: calc(100% - 300px);
        padding: 25px;
        .paymentsMainWrap{
            height: 100%;
            background-color: var(--color-white);
            box-shadow:  0 0 5px 0 rgba(#000,0.2);
            border-radius: 5px;
       
            .paymentsMain{
                height: 100%;
         
                .paymentsMainBody{
                    height: calc( 100% - 50px); 
                    padding: 20px;
                    .paymentsMainScrollable{
                        height: 100%;
                        overflow: hidden;
                        overflow-y: auto;
                        padding-left: 5px;
                        padding-right: 5px;
                        @include md {
      
                            -webkit-overflow-scrolling: touch;
                          }
                    }
                    .paymentsSection{ 
                        margin-bottom: 15px;
                        .title{
                            font-weight: 600;
                            font-size: var(--f-18);
                        }
                        .paymentCardsContainer{ 
                            padding-top: 10px;
                           
                            .paymentCardsRow{
                                display: -ms-flexbox;
                                display: flex;
                                margin-left: -5px;
                                margin-top: -10px;
                                margin-right: -5px;
                                -ms-flex-wrap: wrap;
                                flex-wrap: wrap;
                                
                                .paymentCardsGrid{
                                    -ms-flex: 0 0 20%;
                                    flex: 0 0 20%;
                                    max-width: 20%;
                                    padding: 10px 5px 0 5px;
                                    @include lg {
                                        flex: 0 0 25%;
                                        max-width: 25%;
                                      }
                                }
                            }
                        }

                        &.loyaltyPointsSec {
                            margin-top: 5%;
                        }
                    }
                }
            }
        }
    }
    .basketScreenCont{
        
        -ms-flex: 0 0 300px;
        flex: 0 0 300px;
        max-width: 300px; 
        box-shadow: -1px 0 4px 0 rgba(#000,0.2);
        .basketScreenWrap{
            height: 100%;
        }
    }
}