@import '../../../assets/scss/var';

.cardBtn {
  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(black, 0.2);
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  font-family: var(--ff-sans-pro);
  font-size: var(--f-14);
  overflow: hidden;
  &.cardNow{
    a{
      height: 100%;
    }
  }
}

.cardNow,
.cardSession {
  .btnContent {
    width: 100%;
    height: 100%;
    .btnWrap {
      height: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: stretch;
      align-items: stretch;
      .detailWrap {
        padding: 0px 15px;
        -ms-flex: 0 0 calc(100% - 80px);
        flex: 0 0 calc(100% - 80px);
        max-width: calc(100% - 80px);
        box-sizing: border-box;
        .headerDetails {
          display: -ms-flexbox;
          display: flex;
          .flagTxtWrap {
            min-width: 0;
            .flagTxt {
              background-color: var( --color-3);
              color: var(--color-white);
              border-radius: 0 0 5px 5px;
              padding: 10px;
              padding-top: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .cardTitleWrap {
            padding-left: 10px;
            min-width: 140px;
            display: -ms-flexbox;
            display: flex;
            padding-top: 10px;
            padding-bottom: 10px;
            .cardTitle {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color:  var( --color-3);
              font-weight: 700;
            }
            .subTxt {
              color: var(  --color-4);
              min-width: 80px;
              margin-left: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .cardDetails {
          padding: 15px 0 10px 0;
          .detailList {
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
            list-style: none;
            display: -ms-flexbox;
            display: flex;

            li {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;
              color: var(  --color-4);
              padding: 3px 8px;
              padding-left: 0;
              margin-right: 8px;
              border-right: 1px solid var(  --color-4);;
              line-height: 0;
              &:last-child {
                border-right: none;
                margin-right: 0;
                padding-right: 0;
              }
              .mainTxt {
                font-weight: 700;

                .bbfcLogo {
                  width: 25px;
                  height: auto;
                }
              }
              .subTxt {
                margin-left: 5px;
              }
            }
          }
        }
      }
      .amountWrap {
        -ms-flex: 0 0 80px;
        flex: 0 0 80px;
        max-width: 80px;
        border-radius: 0 5px 5px 0;

        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 15px 5px;
        box-sizing: border-box;
        background-color: var(--color-3);
        .amountTxt {
          color: var(--color-white);
          letter-spacing: 1px;
          font-size: 1.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  &.blue {
    .btnContent {
      .btnWrap {
        .amountWrap {
          background-color: var(--color-3);
        }
      }
    }
  }
  &.gray {
    background-color: var(--color-26);
    pointer-events: none;
    .btnContent {
      .btnWrap {
        .amountWrap {
          background-color: var(  --color-16);
        }
      }
    }
  }
  &.red {
    .btnContent {
      .btnWrap {
        .amountWrap {
          background-color: var( --danger-color);
        }
      }
    }
  }
}

.cardToday {
  .btnContent {
    width: 100%;
    .btnWrap {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: stretch;
      align-items: stretch;
      .detailWrap {
        padding: 0px 15px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        box-sizing: border-box;
        .headerDetails {
          display: -ms-flexbox;
          display: flex;
          .flagTxtWrap {
            .flagTxt {
              background-color: var(--color-3);
              color: var(--color-white);
              border-radius: 0 0 5px 5px;
              padding: 10px;
              padding-top: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .cardTitleWrap {
            padding-left: 10px;
            min-width: 0;
            display: -ms-flexbox;
            display: flex;

            padding-top: 10px;
            padding-bottom: 10px;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;

            -ms-flex-pack: justify;
            justify-content: space-between;
            .cardTitle {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: var(--color-3);
              font-weight: 700;
            }
            .tagList {
              padding-left: 0;
              margin-top: 0;
              margin-bottom: 0;
              list-style: none;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;

              li {
                padding-left: 10px;
                .tagWrap {
                  width: 25px;
                  height: 25px;
                  line-height: 25px;
                  border-radius: 50%;
                  border: 1px solid var(  --color-4);
                  text-align: center;
                  font-size: 0.8em;
                }
              }
            }
          }
        }
        .cardDetails {
          padding: 5px 0 10px 0;
          .detailList {
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
            list-style: none;
            display: -ms-flexbox;
            display: flex;

            li {
              color: var(  --color-4);
              padding: 0px 8px;
              border-right: 1px solid var(  --color-4);
              text-align: center;
              box-sizing: border-box;
              -ms-flex: 0 0 16.6667%;
              flex: 0 0 16.6667%;
              max-width: 16.6667%;
              &:last-child {
                border-right: none;
                margin-right: 0;
                padding-right: 0;
              }
              .mainTxt {
                font-weight: 700;
              }
              .subTxtWrap {
                margin-top: 3px;
                .subTxt {
                  font-size: 13px;
                  line-height: 1em;
                }
              }
              .iconWrap {
                position: relative;
                height: 100%;
                display: -ms-flexbox;
                display: flex;

                -ms-flex-align: center;
                align-items: center;

                .icon {
                  width: 22px;
                  height: 22px;
                  margin: auto;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;

                  &.plusIcon {
                    background-image: url(../../../assets/icons/plus/plus-blue.svg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cardTarifs {
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
  // border-bottom: 7px solid var(  --color-2);
  .btnContent {
    width: 100%;
    .btnWrap {
      .detailWrap {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 15px;
        padding-left: 15px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-direction: column;
        flex-direction: column;

        .titleTxt {
          font-size: var(--f-16);
          font-weight: 600;
          line-height: 1em;
          margin-bottom: 5px;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
          justify-content: center;
          max-width: 100%;
          position: relative;
          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .asterixIcon {
            position: absolute;
            right: -12px;
            top: -5px;
            margin-left: 5px;
            width: 10px;
            height: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(../../../assets/icons/action/star-red.svg);
          }
        }
        .detailTxt {
          font-size: var(--f-14);
          line-height: 1em;
        }
      }
    }
  }
}

.cardPayement {
  .btnContent {
    padding-top: 30px;
    padding-bottom: 30px;
    .btnWrap {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      // .icon{
      //     width: 30px;
      //     height: 30px;
      //     background-size: contain;
      //     background-repeat: no-repeat;
      //     background-position: center;
      //     &.cb{
      //         background-image: url(../../../assets/icons/payment/credit-card.svg);
      //     }
      //     &.cash{
      //         background-image: url(../../../assets/icons/payment/cash.svg);
      //     }
      //     &.check{
      //         background-image: url(../../../assets/icons/payment/check.svg);
      //     }
      // }

      .icon {
        img {
          object-fit: contain;
          height: 25px;
          width: auto;
        }
      }

      .text {
        font-size: var(--f-16);
        margin-top: 5px;
      }
    }
  }
}
