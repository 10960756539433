@import '../../../assets/scss/theme';

.basketScreen {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 112px);
  @include md{
    max-height: calc(100% - 7rem);
    -webkit-overflow-scrolling: touch;
  }
  .emptyCartMsg {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .emptyCartWrapper {
      padding: 15px 15px;
      color: var(--primary-color);
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .cartImg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .basketHeader {
    background-color: var(--basket-head);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .title {
      padding: 15px 15px;
      font-weight: 600;
      color: var(--color-white);
      font-size: var(--f-18);
    }
    .closeIconWrap {
    }
  }
  .basketBody {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .basketBodyscrollable {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      @include md {
      
        -webkit-overflow-scrolling: touch;
      }
    }
    .basketContent {
      .contentHeader {
        background-color: var(--basket-head-secondary);
        .headerTxt {
          padding: 20px 15px 15px 15px;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
        }
      }
      .contentBody {
        background-color: var(--color-5);

        .contentBox {
          .contentTitle {
            border-bottom: var(--border-2);
            padding: 15px 15px;
            display: flex;
            justify-content: space-between;
            .titleTxt {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              color: var(--primary-color);
              font-weight: 600;
              font-size: var(--f-14);

              .movieDate {
                font-weight: 500;
                font-size: var(--f-13);
                color: var( --color-18);
              }
            }
            .subTxt {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              color: var(--primary-color);
              font-weight: 600;
              font-size: var(--f-14);
            }
          }
          .contentMain {
            border-bottom: var(--border-2);
            opacity: 0.85;
            font-size: var(--f-13);
            .contentMainBox {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;
              padding: 0px 15px;

              .contentCountWrap {
                border: var(--border-2);
                width: 25px;
                height: 25px;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;
                .contentCount {
                  color: var(--color-black);
                  width: 25px;

                  &.error {
                    color: var(--danger-color);
                  }
                }
                button{
                  border-radius: 0 !important;
                }
              }
              .contentLabelWrap {
                padding: 15px 15px;
                min-width: 0;
                .contentLabel {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: var(--primary-color);
                  &.error {
                    color: var(--danger-color);
                  }
                }
                .subContentLabel {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: var( --color-18);
                  &.error {
                    color: var(--danger-color);
                  }
                }
              }
              .contentValueWrap {
                margin-left: auto;
                // padding-right: 15px;
                .contentValue {
                  white-space: nowrap;
                  color: var(--color-rgba-4);
                  &.error {
                    color: var(--danger-color);
                  }
                }
                .subContentValue {
                  white-space: nowrap;
                  color: var( --color-18);
                  &.error {
                    color: var(--danger-color);
                  }
                }
              }
              .contentRemoveWrap {
                display: -ms-flexbox;
                display: flex;

                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;
                padding-left: 5px;
                .contentRemove {
                  border: var(--border-3);
                  border-radius: 50%;
                  width: 25px;
                  height: 25px;
                  background-image: url(../../../assets/icons/action/close-light-blue.svg);
                  background-size: 10px;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }
            }
            .contentSubBox {
              padding-left: 30px;
              .contentSubList {
                padding-left: 0;
                margin: 0;
                list-style: none;
                padding-bottom: 15px;
                li {
                  display: -ms-flexbox;
                  display: flex;

                  -ms-flex-align: center;
                  align-items: center;
                  padding: 5px 0;
                  color: var(--color-rgba-4);
                  font-size: var(--f-13);

                  .listCount {
                  }
                  .listLabel {
                    padding-left: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }

          .discountMain {
            border-bottom: var(--border-2);
            opacity: 0.85;
            font-size: var(--f-13);
            .discountMainBox {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;
              padding: 0px 0px;
              .discountContentLabelWrap {
                padding: 15px 15px;
                min-width: 0;
                .contentLabel {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: var(--color-2);
                }
              }
              .discountContentValueWrap {
                margin-left: auto;
                padding-right: 15px;
                .contentValue {
                  white-space: nowrap;
                  color: var(--color-2);
                }
              }
            }
          }
        }
      }
    }
  }
  .basketFooter {
    position: sticky;
    bottom: 0;
    background-color: var(--color-white);
    .actionCont {
      .actionWrap {
        border-top: var(--border-2);
        padding: 15px 15px;
        // &:last-child{
        //     border-bottom: none;
        // }
        &.buttonActionWrap {
          padding: 0;
          .btnTxt {
            font-weight: 600;
            // font-size: 1.1em;
          }
        }

        &.switchActionWrap {
          display: flex;
          justify-content: space-around;
        }
      }
    }
    .mainContentWrap {
      border-top: var(--border-2);
      padding: 15px 15px 15px 15px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-weight: 600;
      // font-size: 1.1em;

      .contentRemoveWrap {
        display: -ms-flexbox;
        display: flex;

        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        .contentRemove {
          border: var(--border-3);
          border-radius: 50%;
          width: 25px;
          height: 25px;
          background-image: url(../../../assets/icons/action/close-light-blue.svg);
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    .mainContentWrap {
      border-top: var(--border-2);
      padding: 15px 15px 15px 15px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-weight: 600;
      .contentLabel {
      }
      .contentValue {
      }
    }
    .mainBtnWrap {
      .btnTxt {
        padding: 20px 15px;
        text-align: center;
        font-size: var(--f-18);

        &.payBtn {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: justify;
          justify-content: space-between;
          font-weight: 600;
        }

        .paybleText {
          font-weight: 700;
        }
      }
    }
  }
}

.fixedSideBasketCont {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  &.active {
    visibility: visible;
    opacity: 1;
    .fixedSideBasketWrap {
      right: 0%;
      .basketScreen {
        max-height: 100% !important;
      }
    }
  }

  .fixedSideBascketOverlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--side-drawer-overlay);
  }
  .fixedSideBasketWrap {
    transition: all 0.3s;
    max-width: 300px;
    width: 100%;
    margin-left: auto;
    background-color: var(--color-white);
    position: absolute;
    top: 52px;
    right: -100%;
    bottom: 0;
  }
}

.basketFooterButtonStyle {
  padding: 0;
  background-color: var(--cta-color);
  background-color: var(--cta-color);
  border-radius: 0;
  color: var(--color-white);
  width: '100%';
  display: 'block';
  text-transform: 'initial';
  line-height: '25px';
  &:hover {
    background-color: var(--cta-color-hover);
  }
}

.basketFooterDisableButtonStyle {
  padding: 0;
  border-radius: 5;
  width: 100%;
  display: block;
  text-transform: initial;
  line-height: 25px;
  color: var(--color-rgba-3);
  box-shadow: none;
  background-color: var(--color-rgba-2);
  cursor: default;
  pointer-events: none;
}
