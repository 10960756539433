.paymentSuccessWrapper{
    .iconWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        img{
            width: auto;
            height: 90px;
            object-fit: contain;
        }
    }
    .textWrapper{
        text-align: center;
    }
}