.seatBox {

  color: var(--color-white);
  width: 40px;
  height: 40px;
  padding: 2px;
  text-align: center;
  font-size: var(--f-13);
  line-height: 30px;
  transition: all 0.3s;
  border: var(  --border-2);
  border-radius: 3px;
  position: absolute;
  
  &.avaialable {
    background-color: transparent;
    color: var(--color-6);
    cursor: pointer;
  }
  &.selected {
    background-color: var(--color-1);
    color: var(--color-white);
    border: 1px solid var(--color-1);
    cursor: pointer;
  }
  &.unavailable {
    background-color: var(--color-7);
    color: var(--color-white);
    pointer-events: none;
  }
}
