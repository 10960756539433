@import '../../../assets/scss/theme';


.ticketSelectWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 112px);
  @include md{
    max-height: calc(100% - 112px);
    -webkit-overflow-scrolling: touch;
  }
  .ticketItemWrapper {
    width: 100%;
    // padding: 25px 20px;
    // overflow: auto;
    // max-height: 84vh;
    // margin-bottom: 108px;
    .contentHeader{
      background-color: var(  --basket-head-secondary);
      .headerTxt{
          padding: 20px 15px 15px 15px;
          font-weight: 600;
      }
    }
    .ticketItem {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      width: 100%;
      padding: 15px;
      &.line-devider {
        margin-bottom: 0px;
        border-bottom: var(--border-2);
        
      }
      .label {
        font-size: var(--f-13);
        text-transform: capitalize;
        color:var(--primary-color);
      }
      .countDevider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .devideIconWrap {
          margin: 0 5px;
          .devideIcon {
            width: 25px;
            height: 25px;
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            &.minus {
              background-image: url(../../../assets/icons/action/minus-purple.svg);
              filter: opacity(0.3);
              cursor: no-drop;
              pointer-events: none;
              &.active {
                cursor: pointer;
                filter: opacity(1);
                pointer-events: initial;
              }
            }
            &.add {
              background-image: url(../../../assets/icons/action/plus-purple.svg);
              filter: opacity(0.3);
              cursor: no-drop;
              pointer-events: none;
              &.active {
                cursor: pointer;
                filter: opacity(1);
                pointer-events: initial;
              }
            }
          }
        }
        .devideBody {
          padding-left: 20px;
          padding-right: 20px;
          width: 150px;
          .seatTicketWrap {
            text-align: center;
            color:var(--primary-color);
            text-transform: uppercase;
            .mainInfo {
              font-size: var(--f-12);
              
              .qty {
                font-weight: 600;
                margin-right: 5px;
              }
              .typeText {
              }
            }
            .ticketPrice {
              font-size: var(--f-14);
              .price {
              }
            }
          }
        }
      }
    }
  }
  .btnTxt {
    padding: 20px 30px;
    text-align: center;
    font-size: var(--f-18);
    text-transform: capitalize;
  }
}
