@import "../../../assets/scss/root";

.confirmBtn{
    background-color: var(--color-12) !important;
    padding: 10px 25px !important;
    color: var(--color-white) !important;
}

.cancelBtn{
    padding: 10px 25px !important;
}

