.scannerCard {
    max-width: fit-content;
  padding-top: 15px;
  width: 30vw;
  box-shadow: none !important;
  // padding: 30px;
  .iconContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 15px 15px;
    font-size: 1.25rem;
    font-family: Source Sans Pro;
    font-weight: 600;
    line-height: 1;
    .icon {
      display: flex;
      margin-bottom: 10px;
      .popupIcon {
        width: auto;
        height: 50px;
        object-fit: contain;
      }
      //   margin-bottom: 10px;
      //   width: 80px;
      //   height: 40px;
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-position: center;

      //   &.scanner {
      //     background-image: url(../../../assets/icons/action/barcode.svg);
      //   }
      //   &.qr {
      //     background-image: url(../../../assets/icons/action/qr.svg);
      //   }
    }
  }
  .descWrapper {
    text-align: center;
  }

  .errorWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .MuiCardContent-root {
    padding: 15px 0 0 0 !important;
    margin-bottom: -15px;
  }
  .MuiCardActions-root {
    padding: 0;
  }

  .formWrap {
    margin-bottom: 0;
  }
  .barcodeInput {
    height: 0;
    outline: none;
    color: #fff;
    border-color: #fff !important;
  }
}
