 
    .alerBoxtTitle{
        text-transform: uppercase;
        text-align: center;
        &.success{
            color: var(--color-2);
        }
        &.info{
            color: var(--color-1);
        }
    } 
    .dialogContent{
        text-align: center;
    }