/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }
  .supplementItem{
    padding-left: 20px !important;
  }
body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

// / change colours to suit your needs /
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

// / change colours to suit your needs /
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

// / change border colour to suit your needs /
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

.container {
  /*//padding: 30px 15px;*/

  text-align: center;
  width: 80mm;
  padding: 0 15px 0 0;
  font-family: "Times New Roman", Times, serif;
  font-family: Arial, Helvetica, sans-serif; }

.mb-1 {
  margin-bottom: 3px; }

.mb-2 {
  margin-bottom: 5px; }

.mb-3 {
  margin-bottom: 10px; }

.mb-4 {
  margin-bottom: 15px; }

.mb-5 {
  margin-bottom: 20px; }

.imgWrap {
  max-width: 150px;
  margin: auto; }

.img-responsive {
  display: block;
  max-width: 100%;
  width: 100%; }

.invoiceNo {
  font-weight: 700;
  font-size: 13px; }

.screen {
  font-size: 18px;
  font-weight: 600; }

.infoTxt {
  font-size: 12px; }

.sep {
  border-bottom: 2px dashed black;
  margin-bottom: 20px;
}

.mainTxt {
  font-weight: 700;
  font-size: 18px; }

.link {
  text-decoration: none;
  color: #000; }

.heighlight {
  font-weight: 700;
  font-size: 16px; }

.table {
  width: 100%;
  font-size: 13px; }
  .table tr td {
    padding: 5px; }
    .table tr td:first-child {
      text-align: right;
      width: 49%; }
    .table tr td:last-child {
      text-align: left;
      width: 49%; }

      @media print {

        .page-end {
            display: block;
            page-break-after: always;
        }
    }

    .bevarage-table tr th {
        padding: 5px;
        font-size: 1.3em;
        font-weight: 700;
    }

    .bevarage-table tr th, .bevarage-table tr td {
        text-align: center !important;
        width: 33% !important;
    }

    .totalRow {

    }

    .totalRow td {
        padding: 10px 5px;
        font-weight: 700;
        font-size: 1.3em;
    }

    .layoutLink {
        text-align: center;
    }

    .table tr td:first-child {
        width: 49%;
        text-align: center;
        vertical-align: middle;
    }

    .table tr td:first-child {
        width: 49%;
        text-align: center;
        vertical-align: middle;
    }

    // / Concession Qty column /
    // / Concession Qty column /
    .concessionDetailTBL.table tr th:first-child {
        width: 20%;
        text-align: left;
        vertical-align: top;
        padding: 0 !important;
    }

    .concessionDetailTBL.table tr td:first-child {
        width: 20%;
        text-align: left;
        vertical-align: top;
        padding: 0 !important;
    }

    /*Concession Description column */
    .concessionDetailTBL.table tr th:nth-child(2) {
        width: 50%;
        text-align: left;
        vertical-align: top;
        padding: 0 !important;
    }

    .concessionDetailTBL.table tr td:nth-child(2) {
        width: 60%;
        text-align: left;
        vertical-align: top;
        padding-left: 0 ;
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }


    /*Concession Total column */
    .concessionDetailTBL.table tr th:nth-child(3) {
        width: 50%;
        text-align: right;
        vertical-align: top;
        padding: 0 !important;

    }

    .concessionDetailTBL.table tr td:nth-child(3) {
        width: 50%;
        text-align: right;
        vertical-align: top;
        padding: 0 !important;
    }


    .summaryTbl.table tr td:first-child {
        width: 100%;
        text-align: left;
        vertical-align: top;
    }

    .summaryTbl.table tr td:nth-child(2) {
        width: 80%;
        text-align: center;
        vertical-align: top;
        padding: 0 !important;
    }

    .reciptText{
   //   margin-top:5px !important;
      margin-bottom: 20px !important;
      vertical-align: middle;
      
    }

    .summaryTbl.table tr td:nth-child(3) {
        width: 100%;
        text-align: right;
        vertical-align: top;
        padding: 0 !important;
    }

    .container{
        padding: 0 15px !important;
    }

    .headerLocationInfoText{
        font-size: 12px;
    }


    table.spacing
    {
        border-collapse:separate;
        border-spacing:0 10px;
    }

    table.spacing small{
        font-size: 10px;
    }

    .page-end {
      // / Used to position the watermark /
      position: relative;
  }

  .container__wrapper {
      // / Center the content /
      align-items: center;
      display: flex;
      justify-content: center;

      // / Absolute position /
      left: 0px;
      position: absolute;
      top: 0px;

      // / Take full size /
      height: 100%;
      width: 100%;
  }

  .container__watermark {
      // / Text color /
      color: rgba(253, 0, 0, 0.31);

      // / Text styles /
      font-size: 3rem;
      font-weight: bold;
      text-transform: uppercase;

      // / Rotate the text /
      transform: rotate(-45deg);

      // / Disable the selection /
      user-select: none;
  }

  .strikethrough {
    text-decoration: line-through;
}

.finalSummaryTbl.table tr td:first-child {
  width: 80%;
  text-align: left;
  vertical-align: top;
}

.finalSummaryTbl.table tr td:nth-child(3) {
  width: 20%;
  text-align: right;
  vertical-align: top;
}