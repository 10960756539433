.topNavigation {
  box-shadow: 0 1px 2px 0 rgba(black, 0.3);
  position: relative;
  z-index: 9;
  .topNavListWrap {
    .topNavList {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      list-style: none;
      display: -ms-flexbox;
      display: flex;

      -ms-flex-align: center;
      align-items: center;

      li {
        -ms-flex-positive: 1;
        flex-grow: 1;
        border-left: 1px solid var(--color-23);
        &.listIconOnly {
          -ms-flex: 0 0 65px;
          flex: 0 0 65px;
          max-width: 65px;
          a {
            padding: 15px 20px;
            width: 100%;
          }
          .listBtn {
            cursor: pointer;
            background-color: var(--color-white);
            transition: all 0.3s;
            &:hover {
              background-color: var(--color-23);
            }
            &.active {
              &:hover {
                background-color: var(--color-25);
              }
            }
          }
        }
        &:first-child {
          border-left: none;
        }

        a {
          &.active {
            background-color: var(--primary-color);
            .icon {
              &.ticket {
                background-image: url(../../../../assets/icons/top-navigation/tickets-white.svg);
              }
              &.confectionery {
                background-image: url(../../../../assets/icons/top-navigation/ice-cream-white.svg);
              }
              &.store {
                background-image: url(../../../../assets/icons/top-navigation/store-white.svg);
              }
              &.vr {
                background-image: url(../../../../assets/icons/top-navigation/vr-white.svg);
              }
              &.pastSales {
                background-image: url(../../../../assets/icons/top-navigation/orders-white.svg);
              }
              &.management {
                background-image: url(../../../../assets/icons/top-navigation/settings-white.svg);
              }
              &.exit {
                background-image: url(../../../../assets/icons/top-navigation/exit-white.svg);
              }
            }
            .text {
              color: var(--color-white);
            }
          }
        }
        .iconBtn {
          &.active {
            background-color: var(--primary-color);
            .btnInside {
              .icon {
                &.back {
                  background-image: url(../../../../assets/icons/top-navigation/back-white.svg);
                }
              }
            }
          }
        }
        a {
          text-decoration: none;
          display: -ms-flexbox;
          display: flex;

          -ms-flex-align: center;
          align-items: center;
          margin: auto;
          width: 100%;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 15px 30px;
          .icon {
            width: 22px;
            height: 22px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &.ticket {
              background-image: url(../../../../assets/icons/top-navigation/tickets-blue.svg);
            }
            &.confectionery {
              background-image: url(../../../../assets/icons/top-navigation/ice-cream-blue.svg);
            }
            &.store {
              background-image: url(../../../../assets/icons/top-navigation/store-blue.svg);
            }
            &.vr {
              background-image: url(../../../../assets/icons/top-navigation/vr-blue.svg);
            }
            &.pastSales {
              background-image: url(../../../../assets/icons/top-navigation/orders-blue.svg);
            }
            &.management {
              background-image: url(../../../../assets/icons/top-navigation/settings-blue.svg);
            }

            &.exit {
              background-image: url(../../../../assets/icons/top-navigation/exit-blue.svg);
            }
          }
          .text {
            color: var(--primary-color);
            margin-left: 15px;
            font-weight: 600;
          }
        }
        .iconBtn {
          background-color: var(--color-white);
          padding: 15px 5px;
          width: 100%;
          outline: none;
          box-shadow: none;
          border: none;
          .btnInside {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: stretch;
            align-items: stretch;
            -ms-flex-pack: center;
            justify-content: center;

            .icon {
              width: 22px;
              height: 22px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              &.back {
                background-image: url(../../../../assets/icons/top-navigation/back-blue.svg);
              }
            }
          }
        }
      }
    }
  }
}
