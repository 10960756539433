@mixin lg {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin md {
  @media screen and (max-width: 768px) {
    @content;
  }
}
