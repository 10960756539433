@import '../../assets/scss/theme';
.dashboardPageContent {
    background-color: var(--color-5);
    display: -ms-flexbox;
    display: flex;
    position: relative;

    .pageMain {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;

        .header {
            padding: 20px;

            .title {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                color: var(--primary-color);;
                font-weight: 600;
                font-size: var(--f-24);

                .icon {
                    width: 24px;
                    height: 24px;
                    background-image: url(../../assets/icons/dashboard/sun.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .text {
                    margin-left: 10px;
                }
            }
        }

        .mainDetails {
            position: relative;
            background-color: var( --color-24);
            padding: 15px 20px;

            .systemDetailsCont {
                .systemDetailsWrap {
                    .systemDetailsList {
                        padding-left: 0;
                        margin-bottom: 0;
                        margin-top: 0;
                        list-style: none;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-align: center;
                        align-items: center;

                        li {
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-align: center;
                            align-items: center;
                            margin-right: 15px;
                            border-right: 1px solid var(--primary-color);;
                            padding-right: 15px;

                            &:last-child {
                                border-right: none;
                            }

                            .icon {
                                width: 18px;
                                height: 18px;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;

                                &.date {
                                    background-image: url(../../assets/icons/dashboard/date.svg);
                                }

                                &.time {
                                    background-image: url(../../assets/icons/dashboard/time.svg);
                                }

                                &.user {
                                    background-image: url(../../assets/icons/dashboard/user.svg);
                                }
                            }

                            .text {
                                color: var(--primary-color);;
                                font-weight: 500;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }

        .pageBody {
            position: relative;
            height: calc(100% - 215px); //50+71+94
            .bodyCont {
                .bodywrap {
                    padding: 30px;
                    .bodyGraphic {
                        background-image: url(../../assets/images/pos-dashboard.jpg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }
        }

        .pageFooter {
            background-color: #fff;
            box-shadow: 0 0 1px 2px rgba(black, 0.1);
            position: relative;

            .footerCont {
                .footerWrap {
                    .footerList {
                        padding-left: 0;
                        margin-bottom: 0;
                        margin-top: 0;
                        list-style: none;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-align: center;
                        align-items: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        li {
                            a {
                                display: block;
                                text-align: center;
                                text-decoration: none;
                                padding: 20px 30px;

                                .icon {
                                    width: 24px;
                                    height: 24px;
                                    margin: auto;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    margin-bottom: 10px;

                                    &.print {
                                        background-image: url(../../assets/icons/dashboard/printer.svg);
                                    }

                                    &.report {
                                        background-image: url(../../assets/icons/dashboard/report.svg);
                                    }
                                }

                                .text {
                                    color: var(--primary-color);;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sideNav {
        -ms-flex: 0 0 300px;
        flex: 0 0 300px;
        max-width: 300px;
        background-color: var(--color-white);
        box-shadow: 0 0 1px 2px rgba(black, 0.2);

        .navHeader {
            
            border-bottom: 2px solid #EDEDED;
            .logoCont {
                padding: 44px 15px;

                .logoWrap {
                    max-width: 180px;
                    margin: auto;
                }
            }
        }

        .navListCont {
            height: calc(100% - 152px);
            overflow: auto;
            @include md {
      
                -webkit-overflow-scrolling: touch;
              }
            .navListWrap {

                a {
                    padding: 25px 30px;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    text-decoration: none;
                    width: 100%;
                    .icon {
                        width: 26px;
                        height: 26px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;

                        &.cashier {
                            background-image: url(../../assets/icons/dashboard/pos.svg);
                        }

                        &.settings {
                            background-image: url(../../assets/icons/dashboard/settings.svg);
                        }

                        &.logOut {
                            background-image: url(../../assets/icons/dashboard/logout.svg);
                        }
                    }

                    .text {
                        margin-left: 15px;
                        font-size: 20px;
                        color: var(--primary-color);
                        font-weight: 600;
                    }
                }
            }
        }

        .navFooter {
            background-color: rgba(var(--primary-color), 0.3);

            .versionDetails {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;
                padding: 10px 15px;

                .icon {
                    width: 16px;
                    height: 16px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    &.version {
                        background-image: url(../../assets/icons/dashboard/version.svg);
                    }
                }

                .text {
                    margin-left: 10px;
                    font-weight: 400px;
                    color: var(--primary-color);;
                }
            }
        }
    }
}
