.iconButtonStyles {
  position: 'absolute';
  right: 5px;
  top: 5px;
}

.paperStyle {
  padding: '20px 30px';
  padding-top: '30px';
  max-width: 320;
}

.titleStyles {
  padding: 0;
  text-align: 'center';
  margin-bottom: 15;
  font-weight: 700;
}

.contentStyles {
  padding: '0px 30px';
}

.actionStyles {
  margin: 0;
  margin-top: 15;
  padding: '0px 30px';
}

.confirmBtnStyle {
  margin: 0;
  padding: 15;
  background-color: var(--color-12);
  border-radius: 5;
  color: var(--color-white);
  width: '100%';
  display: 'block';
  line-height: '25px';
}

.formWrap {
  margin-bottom: 15px;
}
.tarifCountModifierTitleTxt {
  font-weight: 600;
}


.MuiDialog-paper {
  margin: auto !important;
}