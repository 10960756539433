@import '../../../../assets/scss/root';
@import '../../../../assets/scss/theme';

.products {
  box-shadow: 0 0px 5px 0 rgb(0 0 0 / 20%);
  border-bottom: 7px solid;
  margin-bottom: 5px;
  height: auto;
  background-color: var(--color-white);
  border-radius: 5px;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  outline: none;
  overflow: hidden;
  flex: 0 0 30%;
  width: 30%;
  max-width: 30%;
  // margin-right: 20px;
  margin-bottom: 15px;
  margin-right: 15px;
  // font-family: 'Source Sans Pro', serif !important;
  @include lg{
    
      flex: 0 0 45%;
      max-width: 45%;
      width: 45%;
  height: fit-content;
  }
  .btnContent {
    width: 100%;
    height: 100%;
    .btnWrap {
      height: 100%;
      .detailWrap {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        height: 100%;
        .productImageWrap {
          height: 100%;
          .productImage {

            height: 147px;

            width: 100px;

            object-fit: cover;
            min-width: 100px;

            max-height: 147px;

            @include lg{
              width: 90px;           
              min-width: 90px;
            }
          }
        }
        .descWrap {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 15px;
          .productNameWrap {
            .productName {
              font-size: var(--f-14);
              font-weight: 600;
              line-height: inherit;
              margin-bottom: 5px;
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-family: var( --ff-sans-pro);

            }
          }
          .productDescWrap {
            .productDesc {
              font-size: var(--f-14);
              font-weight: 400;
              line-height: inherit;
              margin-bottom: 5px;
              color: var(--color-6);
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              font-family: var(--ff-sans-pro);
            }
          }
          .productPriceWrap {
            .productPrice {
              font-size: var(--f-14);
              font-weight: 600;
              line-height: inherit;
              text-align: left;
              font-family: var(--ff-sans-pro);
            }
          }
          .productQtyWrap {
            margin-top: 25%;
            .productDesc {
              font-size: var(--f-14);
              font-weight: 400;
              line-height: inherit;
              margin-bottom: 5px;
              color: var(--color-6);
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              font-family: var(--ff-sans-pro);
            }
          }
        }
      }
    }
  }

  .categoryBtn {
    width: 100%;
    height: 100%;
  }
}
