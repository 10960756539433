.subDescription {
  text-align: center;
  margin-bottom: 5px;
}

.validationText {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}
.otpInputWrap {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  .formInput {
    background-color: var(--color-white);
    border: 2px solid var(--cta-color-hover);
    border-radius: 50%;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin: 0 12px;
    // font-size: 0;
    color: transparent;
  }
  .indicator {
    display: none;
  }
  input[type=number]:valid {
    background-color: var(--color-13);
    border-radius: 50%;
    display: inline-block;
    height: 18px;
    width: 18px;
  }


  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .otpInput {
  }
}
