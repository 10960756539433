.page404Content {
  background-color: var(--color-27);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .page404 {
    position: relative;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 5%;

    .imgWrap {
      max-width: 500px;
      .page404Img {
        width: 100%;
        max-width: 100%;
      }
    }
    .linkWrap {
      margin-bottom: 30px;
      a {
        text-decoration: none;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        background-color: var(--primary-color);
        padding: 15px 30px;
        border-radius: 5px;
        &:hover {
          background-color: rgba(#425175, 0.9);
        }
      }
    }
  }
}
