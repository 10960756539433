.dataTable{
    height: 100%;
    position: relative;
    .templateContent{
        height: 100%;
        position: relative;
        display: -ms-flexbox;
        display: flex; 
        -ms-flex-align: center;
        align-items: center; 
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-direction: column;
        flex-direction: column;  
        .pageTitle{
            text-transform: uppercase;
            color: var(--primary-color);
            font-weight: 600;
            font-size: var(--f-24);
        }
        .pageIcon{
            margin-top: 30px;
            width: 200px;
            height:  200px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;  
            &.stores {
                background-image: url(../../assets/icons/top-navigation/store-blue.svg);
            }
            &.confectionery {
                background-image: url(../../assets/icons/top-navigation/ice-cream-blue.svg); 
            }
            &.management { 
                background-image: url(../../assets/icons/top-navigation/settings-blue.svg);
            }
            &.vr {
                background-image: url(../../assets/icons/top-navigation/vr-blue.svg); 
            }
            &.past-sales {
                background-image: url(../../assets/icons/top-navigation/orders-blue.svg); 
            }
            &.calendar{
                background-image: url(../../assets/icons/footer-navigation/calendar-blue.svg); 
            }
            &.pmr{
                background-image: url(../../assets/icons/footer-navigation//pmr-blue.svg); 
            }
        }

    }
   
}
.rdt_TableRow{
    padding: 10px 0;
}


.viewBtn{
    display: flex;
    align-items: center;
    padding: 8px 20px !important;
    min-width: 110px !important;
    background-color: var(--color-12) !important;
    color: var(--color-white) !important;
    margin-left: auto !important;
}