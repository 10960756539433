// Colors
:root {
  //colors
  --primary-color: #425175;
  --secondary-color: #566a9a;
  --danger-color: #fa6259;


  --basket-head: #425075;
  --basket-head-secondary: rgba(102, 125, 181, 0.4);
  --side-drawer-overlay: rgba(66, 80, 117,0.9);

  --cta-color: #61b8f2;
  --cta-color-hover: #4899d0;
  
  --color-white: #fff;
  --color-black: #000;

  --color-1: #3939b8;
  --color-2: #0bac0b;
  --color-3: #3f779d;
  --color-4: #666666;
  --color-5: #fafafa;
  --color-6: #838383;
  --color-7: #dddddd;
  --color-8: #c5c5c5;
  --color-9: #f0f0f0;
  --color-10: #e6e6e6;
  --color-11: #a4aec5;
  --color-12: #61b8f2;

  --color-13: #4899d0;
  --color-14: #e0e0e0;
  --color-15: #c7c7c7;
  --color-16: #CCCCCC;
  --color-17: #667DB5;
  --color-18: #999999;
  --color-19: #757575;
  --color-20: #76baff;
  --color-21: #a9d4ff;
  --color-22: #222222;
  --color-23: #D0D7E7;
  --color-24: #f0f2f7;
  --color-25: #354364;
  --color-26: #EDEDED;
  --color-27: #d1d8e8;
  --color-28: #f5f5f58f;
 


  --color-rgba-1: rgba(0, 0, 0, 0.87);
  --color-rgba-2: rgba(0, 0, 0, 0.12);
  --color-rgba-3: rgba(0, 0, 0, 0.26);
  --color-rgba-4: rgba(0, 0, 0, 0.7);

  //font family
  --ff-sans-pro: 'Source Sans Pro', serif !important;

  //fonts size
  --f-12:12px;
  --f-13:13px;
  --f-14: 14px;
  --f-16: 16px;
  --f-18: 18px;
  --f-20: 20px;
  --f-24: 24px;
  --f-25: 25px;

  //box shadow
  --shadow-1:0 2px 4px 0 rgb(0 0 0 / 20%);
  --shadow-2:0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  //border
  --border-1: 1px solid rgba(0,0,0,.12);
  --border-2: 1px solid var(--color-16);
  --border-3: 2px solid var(--color-17);
}
