 
.numberPad{
    // max-width: 220px;
    max-width: 100%;
    margin: auto;
   
    .simple-keyboard{ 
        box-shadow: 0 1px 5px 0 rgba(#000,0.2);
        font-family: var(--ff-sans-pro);
        &.hg-theme-default{
            padding: 15px;
            background-color: var(--color-24);
            .hg-button{
                height: 50px;
                border-radius: 3px;
                color: var(  --color-18);
                box-shadow:0 2px 3px 0px rgba(#000,0.1);
                border-bottom: none;
                font-weight: 600;
                font-size: var( --f-16);
                &.backspaceBtn{
                // text-indent: -999px;
                    overflow: hidden;
                    color: transparent;
                    background-size: 26px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(../../../../assets//icons//keyboard//num-backspace-gray.svg); 
                }
                &.decimalBtn{
                    font-size: var( --f-18);
                    span{
                        display: inline-block;
                        vertical-align: middle;
                        padding-bottom: 10px;
                    }
                }
              
            }
        } 
       .hg-row:not(:last-child){
        margin-bottom: 8px;
       }
       .hg-row .hg-button-container, 
       .hg-row .hg-button:not(:last-child){
           margin-right: 8px;
       }
    }
   
}
