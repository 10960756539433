@import '../../../assets/scss/theme';

.mainLayoutPageContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .pageBody {
    height: 100%;
    box-shadow: 0 0px 1px 0 rgba(black, 0.1);
    position: relative;
    z-index: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    // -webkit-box-pack: center;
    // -webkit-justify-content: center;
    // -moz-box-pack: center;
    // -ms-flex-pack: center;
    // justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--color-5);

    .gridScrollContainer {
      padding: 16px;
      overflow: hidden;
      overflow: auto;
      height: 100%;
      max-height: calc(100vh - 112px);
      @include md {
        max-height: calc(100% - 7rem);
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}
