.foodsCategory {
  box-shadow: var(--shadow-1);
  margin-bottom: 15px;
  height: auto;
  max-width: 175px;
  min-width: 175px;
  margin-right: 20px;

  .btnContent {
    width: 100%;
    .btnWrap {
      .detailWrap {
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        flex-direction: row;
        .colorCodeWrap{
          height: 100%;
          .color{
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-left: 15px;
          }
        }
        .titleTxt {
          font-size: var(--f-14);
          font-weight: 600;
          line-height: 1em;
          display: flex;
          justify-content: center;
          max-width: 100%;
          padding: 20px 50px 20px 10px ;
          position: relative;
          font-family: var(  --ff-sans-pro);
          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 20px;
            // text-transform: uppercase;
          }
        }
      }
    }
  }

  &.active {
    background-color: var(--primary-color);
    // color: var(--color-white);
  }

  .categoryBtn {
    width: 100%;
  }
}
