@import '../../../assets/scss/theme';
.past-sales-view {
  padding: 0 !important;
}
.pastSalesView {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  .pastSalesMainCont {
    -ms-flex: 0 0 calc(100% - 300px);
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    padding: 25px;
    .pastSalesMainWrap {
      height: 100%;
      background-color: var(--color-white);
      box-shadow: 0 0 5px 0 rgba(#000, 0.2);
      border-radius: 5px;

      .pastSalesMain {
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include md {
      
          -webkit-overflow-scrolling: touch;
        }
        .pastSalesMainHeader {
          background-color: var(--color-3);
          display: -ms-flexbox;
          display: flex;

          -ms-flex-align: center;
          align-items: center;
          color: var(--color-white);
          padding-left: 15px;
          padding-right: 15px;
          position: sticky;
          /* width: 100%; */
          left: 0;
          right: 0;
          top: 0;
          z-index: 99;
          .titleDetails {
            display: flex;
            flex-direction: column;
            .title {
              font-size: var(--f-14);
              font-weight: 600;
              line-height: 1;
              margin-bottom: 2px;
            }
            .name {
              font-size: var(--f-14);
            }
          }
          .productTitle {
            padding-left: 15px;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .purchasedDetails {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 15px;
            margin-left: auto;
            // width: 100%;
            // max-width: 400px;
            .detailList {
              padding-left: 0;
              list-style: none;
              margin: 0;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;
              -ms-flex-pack: end;
              justify-content: flex-end;

              li {
                padding: 0px 8px;
                padding-left: 0;
                margin-right: 8px;
                border-right: 1px solid var(--color-white);

                &:last-child {
                  border-right: none;
                  margin-right: 0;
                  padding-right: 0;
                }
                .detail {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .pastSalesMainBody {
          height: calc(100% - 50px);
          padding: 20px;
          .pastSalesMainScrollable {
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            padding-left: 5px;
            padding-right: 5px;
            @include md {
      
              -webkit-overflow-scrolling: touch;
            }
          }
          .pastSalesSection {
            margin-bottom: 15px;
            .title {
              font-weight: 600;
              font-size: var(--f-18);
            }
            .tarifCardsContainer {
              padding-top: 10px;

              .tarifCardsRow {
                display: -ms-flexbox;
                display: flex;
                margin-left: -5px;
                margin-top: -10px;
                margin-right: -5px;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;

                .tarifCardsGrid {
                  -ms-flex: 0 0 20%;
                  flex: 0 0 20%;
                  max-width: 20%;
                  padding: 10px 5px 0 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .basketScreenCont {
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    max-width: 300px;
    box-shadow: -1px 0 4px 0 rgba(#000, 0.2);
    .basketScreenWrap {
      height: 100%;
    }
  }
}

.totalDescriptionWrap {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: var(--color-white);
  box-shadow: 0px -2px 8px 0px #00000014;
  margin: 0 -20px;
  .subTotalDescription {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px 30px;
    .subTitleWrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title {
        font-size: var(--f-14);
        font-weight: 600;
      }
      .value {
        font-size: var(--f-14);
        font-weight: 600;
      }
    }
  }
  .totalDescription {
    padding: 20px 30px;
    .subTitleWrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
      .title {
        font-size: var(--f-14);
        font-weight: 600;
      }
      .value {
        font-size: var(--f-14);
        font-weight: 600;
      }
    }
    .totalTitleWrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title {
        font-size: var(--f-20);
        font-weight: 600;
      }
      .value {
        font-size: var(--f-20);
        font-weight: 600;
      }
    }
  }
}

.refundScreenCont {
  -ms-flex: 0 0 300px;
  flex: 0 0 300px;
  max-width: 300px;
  box-shadow: -1px 0 4px 0 rgba(#000, 0.2);
  .refundScreenWrap {
    height: 100%;

    .refundScreen {
      height: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      overflow: auto;
      @include md {
      
        -webkit-overflow-scrolling: touch;
      }
      .emptyCartMsg {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .emptyCartWrapper {
          padding: 15px 15px;
          color: var(--primary-color);
          font-weight: 600;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .cartImg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .refundHeader {
        background-color: var(--basket-head);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .title {
          padding: 15px 15px;
          font-weight: 600;
          color: var(--color-white);
          font-size: var(--f-18);
        }
        .closeIconWrap {
        }
      }
      .basketBody {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        .basketBodyscrollable {
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          @include md {
      
            -webkit-overflow-scrolling: touch;
          }
          .btnContentWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: var(--border-2);
            padding: 15px 15px;
            .icon {
              border: var(--border-3);
              border-radius: 50%;
              width: 28px;
              height: 28px;
              background-size: 15px;
              background-repeat: no-repeat;
              background-position: center;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url(../../../assets/icons/action/printer.svg);
            }
          }
        }
        .basketContent {
          .contentHeader {
            background-color: var(--basket-head-secondary);
            .headerTxt {
              padding: 20px 15px 15px 15px;
              font-weight: 600;
            }
          }
          .contentBody {
            background-color: var(--color-5);

            .contentBox {
              .contentTitle {
                border-bottom: var(--border-2);
                padding: 15px 15px;
                .titleTxt {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  color: var(--primary-color);
                  font-weight: 600;
                  font-size: var(--f-14);
                }
              }
              .contentMain {
                border-bottom: var(--border-2);
                opacity: 0.85;
                font-size: var(--f-14);
                .contentMainBox {
                  display: -ms-flexbox;
                  display: flex;
                  -ms-flex-align: center;
                  align-items: center;
                  padding: 0px 15px;

                  .contentCountWrap {
                    border: var(--border-2);
                    width: 25px;
                    height: 25px;
                    display: -ms-flexbox;
                    display: flex;

                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    .contentCount {
                      color: var(--color-black);
                      width: 25px;
                    }
                  }
                  .contentLabelWrap {
                    padding: 15px 15px;
                    min-width: 0;
                    .contentLabel {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      color: var(--primary-color);
                    }
                  }
                  .contentValueWrap {
                    margin-left: auto;
                    padding-right: 15px;
                    .contentValue {
                      white-space: nowrap;
                      color: rgba(#000, 0.7);
                    }
                  }
                  .contentRemoveWrap {
                    display: -ms-flexbox;
                    display: flex;

                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    .contentRemove {
                      border: var(--border-3);
                      border-radius: 50%;
                      width: 25px;
                      height: 25px;
                      background-image: url(../../../assets/icons/action/close-light-blue.svg);
                      background-size: 10px;
                      background-repeat: no-repeat;
                      background-position: center;
                    }
                  }
                }
                .contentSubBox {
                  padding-left: 30px;
                  .contentSubList {
                    padding-left: 0;
                    margin: 0;
                    list-style: none;
                    padding-bottom: 15px;
                    li {
                      display: -ms-flexbox;
                      display: flex;

                      -ms-flex-align: center;
                      align-items: center;
                      padding: 5px 0;
                      color: rgba(#000, 0.6);
                      font-size: var(--f-14);

                      .listCount {
                      }
                      .listLabel {
                        padding-left: 15px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .refundFooter {
        .actionCont {
          .actionWrap {
            border-top: var(--border-2);
            padding: 15px 15px;
            // &:last-child{
            //     border-bottom: none;
            // }
            &.buttonActionWrap {
              padding: 0;
              .btnTxt {
                font-weight: 600;
                // font-size: 1.1em;
              }
            }
          }
        }
        .mainContentWrap {
          border-top: var(--border-2);
          padding: 15px 15px 15px 15px;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: justify;
          justify-content: space-between;
          font-weight: 600;
          .contentLabel {
          }
          .contentValue {
          }
        }
        .mainBtnWrap {
          .btnTxt {
            padding: 20px 30px;
            text-align: center;
            font-size: var(--f-18);
          }
        }
      }

      .refundReasonBlock {
        display: flex;
        align-items: flex-start;
        padding: 15px 15px;
        flex-direction: column;
        p{
          &:not(:last-child){
            margin-bottom: 5px;
          }
        }
      }
    }

    .fixedSideBasketCont {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      &.active {
        visibility: visible;
        opacity: 1;
        .fixedSideBasketWrap {
          right: 0%;
        }
      }
      .fixedSideBascketOverlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: var(--side-drawer-overlay);
      }
      .fixedSideBasketWrap {
        transition: all 0.3s;
        max-width: 300px;
        width: 100%;
        margin-left: auto;
        background-color: var(--color-white);
        position: absolute;
        top: 52px;
        right: -100%;
        bottom: 0;
      }
    }

    .basketFooterButtonStyle {
      padding: 0;
      background-color: var(--cta-color);
      border-radius: 0;
      color: var(--color-white);
      width: '100%';
      display: 'block';
      text-transform: 'initial';
      line-height: '25px';
      &:hover {
        background-color: var(--cta-color-hover);
      }
    }

    .basketFooterDisableButtonStyle {
      padding: 0;
      border-radius: 5;
      width: '100%';
      display: 'block';
      text-transform: 'initial';
      line-height: '25px';
      color: 'rgba(0, 0, 0, 0.26)';
      box-shadow: 'none';
      background-color: rgba(0, 0, 0, 0.12);
      cursor: default;
      pointer-events: 'none';
    }
  }
}
