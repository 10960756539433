.priceDetailsWrapper{
    .paymentImageWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        img{
            width: auto;
            height: 50px;
            object-fit: contain;
        }
    }
    .priceWrapper{
        text-align: center;
        font-size: var(--f-25);
        font-weight: 700;
        margin-bottom: 5px;
    }
    .descWrapper{
        text-align: center;
    }

    .returnPriceWrapper {
        text-align: center;
        font-size: var(--f-20);
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 15px;
    }

    .cashdescWrapper {
        text-align: center;
        margin-bottom: 5px ;
    }

    .numberPadWrap {
        margin-top: 5px ;
    }
}