@import "../../../assets/scss/root";
@import "../../../assets/scss/var";

.legendsWrap {
  // padding-top: 50px;
  .legends {
    padding-left: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    .legendBox {
     
      padding-left: 25px;
      position: relative;
      &:not(:last-child){
        margin-right: 25px;
      }
      &:before {
        content: "";
        width: 15px;
        height: 15px;
        display: block;
        position: absolute;
        left: 0;
        top: 4px;
      }
      .text {
        font-size: var(--f-14);
        color: var(--color-black);
      }
    }
    .selected {
      &:before {
        background-color: var(--color-1);
      }
    }
    .available {
      &:before {
        background-color: transparent;
        border: 1px solid var(--color-6);
      }
    }

    .unavailable {
      &:before {
        background-color: var(--color-7);
        border-color: var(--color-7);
      }
    }
  }
}
