.tarifButton {
    padding: 0;
    min-height: 50;
    width: '100%';
    height: '100%';
    
    .disabled {
      opacity: 0.5;
      background-color: var(--color-15);
    }
}