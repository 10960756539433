.priceDetailsWrapper{
    .paymentImageWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        img{
            width: auto;
            height: 50px;
            object-fit: contain;
        }
    }
    .priceWrapper{
        text-align: center;
        font-size: var(--f-25);
        font-weight: 700;
        margin-bottom: 5px;
    }
    .descWrapper{
        text-align: center;
    }
}

.button-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}