@import '../../assets/scss/theme';
.loginPageContent{ 
    background-color:var( --color-27);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .loginPageCont{
        flex: 1 1;
        display: inline-block;
        justify-content: center;
        flex-direction: column;
        overflow: auto;
        @include md {
      
            -webkit-overflow-scrolling: touch;
          }
        .loginPageWrap{
            display: flex;
            justify-content: center;
            align-items: center;
            height: -webkit-fill-available;
            margin: auto;  
            margin-top: 30px;
            margin-bottom: 30px;;
            position: relative;
            width: 100%;
            max-width: 360px;
            .loginHelperCont{
                position: absolute;
                right: 100%;
                top: 0;
                padding-right: 30px;
                .loginHelperWrap{
                    
                }
            }
        }
    }
    
}
.loginFormCont{
    // margin: auto;
    background-color: var( --color-24);   
    padding: 20px 20px 20px 20px;
    box-shadow: 0 0 5px 1px rgba(black, 0.4); 
    border-radius: 5px;
    width: 350px;
    .logoCont{
        margin-bottom: 25px;
        .logoWrap{
            max-width: 180px;
            margin: auto;
        } 
    }
    .loginFormWrap{
        .btnWrap{
            width:100%;
            // max-width: 200px;
            margin: auto;
        }
    }
}

.loginFooter{
        background-color: var(--color-white);
        position: relative;
    .versionDetails {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10px 15px;

        .icon {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &.version {
                background-image: url(../../assets/icons/dashboard/version.svg);
            }
        }

        .text {
            margin-left: 10px;
            font-weight: 400px;
            color: var(--primary-color);
        }
    }
    .numPadToggler{
        position: absolute;
        right: 0;
        bottom: 0; 
        background-color: var(--primary-color);
        padding: 10px 35px;
        &:hover{
            cursor: pointer;

        }
        .icon{
            width: 50px;
            height: 50px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;   
            margin: auto;
            &.keyboard{
                background-image: url(../../assets/icons/login/keyboards.svg);
            }
        }
        .text{
            color: var(--color-white);
            text-align: center;
            line-height: 0.9em;
        }
    }
}

.keyboardContainer{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 999;
    .numberPadWrap{
        width: 100%;
    }
}
.pwdError{
    display: none;
    margin-top: 5px;
    &.show{
        display: block;
    }
}
.error{
    color: var(--danger-color);
} 

.hg-layout-default {
    font-family: var(--ff-sans-pro) !important;
  }
  