@import "../../assets/scss/theme";


.tarifsView {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  .tarifsMainCont {
    -ms-flex: 0 0 calc(100% - 300px);
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    padding: 25px;
    .tarifsMainWrap {
      height: 100%;
      background-color: var(--color-white);
      box-shadow: 0 0 5px 0 rgba(#000, 0.2);
      border-radius: 5px;

      .tarifsMain {
        height: 100%;
        display: flex;
        flex-direction: column;
        .tarifsMainHeader {
          background-color: var(--color-3);
          display: -ms-flexbox;
          display: flex;

          -ms-flex-align: center;
          align-items: center;
          color: var(--color-white);
          padding-left: 15px;
          padding-right: 15px;
          .flag {
            background-color: var(--color-white);
            padding: 6px 10px;
            padding-top: 15px;
            -ms-flex-item-align: start;
            align-self: flex-start;
            border-radius: 0 0 5px 5px;
            min-width: 0;
            .flagTxt {
              color: var(--color-3);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .productTitle {
            padding-left: 15px;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .productDetails {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 15px;
            margin-left: auto;
            // width: 100%;
            // max-width: 400px;
            .detailList {
              padding-left: 0;
              list-style: none;
              margin: 0;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;
              -ms-flex-pack: end;
              justify-content: flex-end;

              li {
                padding: 0px 8px;
                padding-left: 0;
                margin-right: 8px;
                border-right: 1px solid var(--color-white);

                &:last-child {
                  border-right: none;
                  margin-right: 0;
                  padding-right: 0;
                }
                .detail {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .tarifsMainBody {
          height: calc(100% - 50px);
          padding: 20px;
          .tarifsMainScrollable {
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            padding-left: 5px;
            padding-right: 5px;
            @include md {
      
              -webkit-overflow-scrolling: touch;
            }
          }
          .tarifsSection {
            margin-bottom: 15px;
            .title {
              font-weight: 600;
              font-size: var(--f-18);
            }
            .tarifCardsContainer {
              padding-top: 10px;

              .tarifCardsRow {
                display: -ms-flexbox;
                display: flex;
                margin-left: -5px;
                margin-top: -10px;
                margin-right: -5px;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;

                .tarifCardsGrid {
                  flex: 0 0 20%;
                  max-width: 20%;
                  padding: 10px 5px 0 5px;
                  @include lg {
                    flex: 0 0 25%;
                    max-width: 25%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .basketScreenCont {
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    max-width: 300px;
    box-shadow: -1px 0 4px 0 rgba(#000, 0.2);
    .basketScreenWrap {
      height: 100%;
    }
  }
}
