@import './assets//scss//root';
@import './assets//scss//var';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--ff-sans-pro);
}
*,
::after,
::before {
  box-sizing: border-box;
}
* {
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: -moz-none;
  // -o-user-select: none;
  // user-select: none;
}
#root {
  min-width: 1024px;
  overflow: hidden;
}
.fullHeight {
  position: relative;
  height: 100%;
}
.img-fluid {
  max-width: 100%;
  width: 100%;
  display: block;
}
.hide {
  display: none;
}

.pageContent {
  // overflow: auto;
}
.gridContainer {
  padding: 20px;
}

.formGroup {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .inputLabel {
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--color-22);
  }
  .formControl {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    .formInput {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 10px 5px 10px 10px;
      box-shadow: none;
      border: 2px solid var(--color-16);
      &:focus {
        outline: none;
        border-color: var(--primary-color);
      }
    }

    .inputError {
      border: 2px solid var(--danger-color) !important;
    }
  }
  .helperTxt,
  .inputMsg {
    display: none;
  }
}

.input-error {
  color: var(--danger-color) !important;
}

.hg-theme-default .hg-button {
  height: 55px;
  font-size: 14px;
}
