@import '../../../assets/scss/root';

.reactCalendar {
  width: 100%;
  max-width: 100%;
  background: var(--color-white);
  // border: 1px solid var(--color-8);
  border: none;
  font-family: var(--ff-sans-pro);
  line-height: 1.125em;
  border-radius: 3px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  height: 60px;
  border: var(--border-1);
  // border-radius: 3px;
  // font-size: 16px;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: auto;
  margin-bottom: 1em;
  border-radius: 6px;
  overflow: hidden;
  align-items: center;
  border: none;
  justify-content: center;
}
.react-calendar__navigation button {
  min-width: 50px;
  background: none;

  &.react-calendar__navigation__next-button,
  &.react-calendar__navigation__next2-button,
  &.react-calendar__navigation__prev2-button,
  &.react-calendar__navigation__prev-button {
    text-indent: 70px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    filter: grayscale(100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    &:hover,
    &:focus {
      background-color: unset !important;
    }
  }
  &.react-calendar__navigation__next-button {
    background-image: url(../../../assets/icons/action/arrow.svg);
  }
  &.react-calendar__navigation__next2-button {
    background-image: url(../../../assets/icons/action/double-arrow.svg);
  }
  &.react-calendar__navigation__prev2-button {
    background-image: url(../../../assets/icons/action/double-arrow.svg);
    transform: rotate(180deg);
  }
  &.react-calendar__navigation__prev-button {
    background-image: url(../../../assets/icons/action/arrow.svg);
    transform: rotate(180deg);
  }
}
.react-calendar__navigation button:disabled {
  background-color: var(--color-28);
  background-color: unset !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--color-10);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--f-12);
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-size: var(--f-12);
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--f-12);
  font-weight: bold;
}
.react-calendar__month-view__days {
  border-radius: 6px;
  border: 0.1px solid var(--color-8);
  overflow: hidden;
}
.react-calendar__month-view__days__day--weekend {
  color: var(--danger-color);
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--color-19);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: var(--color-9);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--color-8);
}
.react-calendar__tile--now {
  background: var(--secondary-color);
  color: var(--color-white);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--color-3);
}
.react-calendar__tile--hasActive {
  background: var(--color-20);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--color-21);
}
.react-calendar__tile--active {
  background: var(--color-19);
  color: var(--color-white);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--color-3);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--color-10);
}
.react-calendar__navigation__label {
  border-radius: 6px;
  flex-grow: unset !important;
  height: auto !important;
  padding: 15px;
}

abbr {
  text-decoration: unset !important;
  cursor: initial !important;
  text-decoration: unset !important;
  border: none !important;
}
