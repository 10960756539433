@import '../../../assets/scss/theme';
.seatSelectionCont {
  padding-top: 20px;
  //   padding-bottom: 30px;
  height: 100%;
  .seatSelectionWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .seatPlan {
      .screenCont {
        display: none;
        margin-bottom: 20px;
        .screenWrap {
          max-width: 80%;
          margin: auto;
          .screenImg {
            img {
            }
          }
        }
      }
      .seatStructureWrap {
        margin-top: 20px;

        .seatStructure {
          overflow: auto;
          height: 100%;
          padding: 0 15px;
          @include md {
      
            -webkit-overflow-scrolling: touch;
          }
          .blockPlanContainer {
            margin: auto;
            position: relative;
          }
        }
      }
    }
    .ticketSelecterWrap {
      z-index: 99;
      background-color: var(--color-white);
      box-shadow: 0px -2px 8px 0px rgb(0 0 0 / 8%);
      padding: 20px;
      display: flex;
      justify-content: center;
      position: static;
      bottom: 0;
      .ticketSelecter {
      }
    }
  }
}
