.contentBodyWrapper {
  padding: 0 20px;
  height: inherit;
  .contentBody {
    height: inherit;

    .gridWrapper {
      height: inherit;

      .gridHead {
        position: relative;

        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .listTitles {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);

          .title {
            padding: 15px;
            font-size: var(--f-14);
            font-weight: 400;
            &:first-child {
              width: 10%;
            }
            &:nth-child(2) {
              width: 45%;
              &.refund{
                width:70%;
              }
            }
            &:nth-child(3) {
              width: 10%;
              &.price{
                text-align: right;
              }
            }
            &:nth-child(4) {
              width: 15%;
              text-align: right;
            }
            &:nth-child(5) {
              width: 10%;
              text-align: right;
            }
            &:nth-child(6) {
              width: 10%;
              text-align: right;
            }
          }
        }
        .dataWrapper {
          margin-bottom: 1rem;
          .dataRow {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            .ticketData {
              padding: 15px;
              font-size: var(--f-14);
              font-weight: 400;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              &:first-child {
                width: 10%;
              }
              &:nth-child(2) {
                width: 45%;
                &.refund{
                  width:70%;
                }
                
              }
              &:nth-child(3) {
                width: 10%;
                &.price{
                  text-align: right;
                }
              }
              &:nth-child(4) {
                width: 15%;
                text-align: right;
              }
              &:nth-child(5) {
                width: 10%;
                text-align: right;
              }
              &:nth-child(6) {
                width: 10%;
                text-align: right;
              }
              
            }
          }
        }
      }
    }
  }
}
