@import '../../assets/scss/root';
@import '../../assets/scss/theme';

.filterWrapper {
  display: flex;
  flex-direction: row;

  .searchWrapper {
    margin: 0 20px 20px 0;
    .titleLabel {
      font-weight: 600;
      font-size: var(--f-16);
      margin-bottom: 5px;
    }
    .searchRow {
      display: flex;
      flex-direction: row;

      .formControl {
        margin-right: 10px;
        .formInput {
          min-width: 250px;
        }
      }
      .buttonWrapper {
        .searchBtn {
          background-color: var(--color-12) !important;
          padding: 10px 25px !important;
          color: var(--color-white) !important;
        }
      }
    }
  }
}

.rdt_Table {
  max-height: calc(100vh - 220px);
  overflow: auto;
  @include md{
    max-height: calc(100% - 220px);
    -webkit-overflow-scrolling: touch;
  }
  .rdt_TableHead {
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    z-index: 99;
    font-size: var(--f-14) !important;
    font-family:  var(--ff-sans-pro) !important;
  }
  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        font-size: var(--f-14) !important;
        font-family:  var(--ff-sans-pro) !important;
      }
    }
  }
}
