@import '../../../../assets/scss/root';
@import '../../../../assets/scss/theme';

.mainHeader {
  background-color: var(--color-24);
  .mainHeaderWrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    .mainDetails {
    }
    .mainActions {
      margin-left: auto;
    }
  }
}

.mainDetails {
  position: relative;
  padding: 20px 20px;

  @include lg{

    padding: 20px 13px;
  }

  .systemDetailsCont {
    .systemDetailsWrap {
      .systemDetailsList {
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 0;
        list-style: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;

        li {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          margin-right: 15px;
          border-right: 1px solid var(--primary-color);
          padding-right: 15px;

          @include lg{
            padding-right: 10px;
            margin-right: 10px;
          }
          &:last-child {

            border-right: none;

            padding-right: 0;
            margin-right: 0;
          }


          .icon {
            width: 18px;
            height: 18px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &.date {
              background-image: url(../../../../assets/icons/dashboard/date.svg);
            }

            &.time {
              background-image: url(../../../../assets/icons/dashboard/time.svg);
            }

            &.user {
              background-image: url(../../../../assets/icons/dashboard/user.svg);
            }
            &.pos {
              background-image: url(../../../../assets/icons/dashboard/pos.svg);
            }
          }

          .text {
            color: var(--primary-color);
            font-weight: 500;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.mainActions {
  .mainActionsWrap {
    .mainActionList {
      padding-left: 0;
      margin-bottom: 0;
      margin-top: 0;
      list-style: none;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      // margin-right: 10px;
      .listItemWrapper {
        display: flex;
        align-items: center;
        margin: 0 5px;
        &.seperator{
          display: flex;
          align-items: center;
          margin-left: 10px;
          border-left: 1px solid var(--primary-color);
          padding-left: 10px;
        }
        &:first-child {
          margin-right: 15px;
          border-right: 1px solid var(--primary-color);
          padding-right: 15px;
        }
        .text {
          margin-right: 10px;
          color: var(--primary-color);
        }
        .actionBtn {
          color: var(--primary-color);
          font-weight: 600;
        }
        .searchSales {
          .formControl {
            position: relative;
            display: flex;

            overflow: hidden;
            .formInput {
              flex: 1 1 auto;
              padding: 10px 5px 10px 10px;
              box-shadow: none;
              border: var(--border-2);
              border-radius: 4px;
              font-size: var(--f-14);
              font-family: var(--ff-sans-pro);
              height: 36px;
              @include lg{
                max-width: 135px;
              }
              &:focus {
                outline: none;
                border-color: var(--primary-color);
              }
              &.no-border{
                border: none;

              }
            }
          }
        }
      }
      li {
        background-color: var(--color-white);
        border-radius: 3px;
        box-shadow: 0 1px 3px 0 rgba(black, 0.4);
        margin-left: 5px;
        margin-right: 5px;
        &.listIconOnly {
          background-color: var(--primary-color);
          border-radius: 0;
          box-shadow: none;
          margin-right: 0;
          .actionBtn {
            padding: 15px 30px;
            padding-right: 25px;
            .icon {
              position: relative;
              width: 30px;
              height: 30px;
              background-image: url(../../../../assets/icons/main-header/cart-white.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;

              .tarifCount {
                position: absolute;
                right: -5px;
                top: -5px;
                background-color: var(--danger-color);
                border-radius: 50%;
                width: 16px;
                height: 16px;
                display: block;
                text-align: center;
                line-height: 15px;
                font-size: var(--f-12);
                color: var(--color-white);
              }
            }
          }
        }
        .actionBtn {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          padding: 8px 20px;

          &.disable {
            color: var( --color-rgba-3);
            pointer-events: none;
          }

          @include lg{
            padding: 8px 15px;
          }
          
          .icon {
            width: 18px;
            height: 18px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
            &.card {
              background-image: url(../../../../assets/icons/main-header/card-blue.svg);
            }
            &.search {
              background-image: url(../../../../assets/icons/main-header/search-blue.svg);
            }
          }
          .text {
            margin-left: 10px;
            color: var(--primary-color);
            font-weight: 600;
          }
          .dateWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: var(--primary-color);
            font-weight: 600;
            .icon {
              margin-right: 10px;
              &.date {
                background-image: url(../../../../assets/icons/dashboard/date.svg);
              }
            }
          }

          .iconWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: var(--primary-color);
            font-weight: 600;
            .icon {
              margin-right: 10px;

              &.confectionery-menu {
                background-image: url(../../../../assets/icons/main-header/card-blue.svg);
              }
              &.confectionery-card {
                background-image: url(../../../../assets/icons/main-header/card-blue.svg);
              }
              &.confectionery-card-qr {
                width: 16px;
                height: 16px;
                background-image: url(../../../../assets/icons/main-header/qr.svg);
              }
            }
          }
        }
      }
    }
  }
}
