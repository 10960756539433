@import '../../assets/scss/theme';

.product-grid-container {
  height: fit-content;
  display: flex;
}

//new view

.confectioneryView {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  .confectioneryMainCont {
    -ms-flex: 0 0 calc(100% - 300px);
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    padding: 25px;
    .confectioneryMainWrap {
      background-color: var(--color-white);
      box-shadow: 0 0 5px 0 rgba(#000, 0.2);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      height: 100%;
      .confectioneryMain {
        margin-bottom: 15px;
        .foodCategories {
          display: flex;
          flex-direction: row;
          overflow: auto;
          //  width: 100%;
          margin-right: 20px;
          padding: 20px;
          padding-bottom: 5px;
          border-bottom: var(--border-1);
          @include md {
      
            -webkit-overflow-scrolling: touch;
          }
        }
        .categoryDetailsWrap {
          max-height: calc(100vh - 278px);
          overflow: hidden;
          overflow-y: auto;
          @include md{
            max-height: calc(100% - 278px);
            -webkit-overflow-scrolling: touch;
          }
          .categoryDetails {
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            display: flex;
            justify-content: flex-start;
            @include md{
              height: 52vh;
            }
          }
        }
      }
    }
  }
  .basketScreenCont {
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    max-width: 300px;
    box-shadow: -1px 0 4px 0 rgba(#000, 0.2);
    .basketScreenWrap {
      height: 100%;
    }
  }
}
